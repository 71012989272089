@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: "IBM Plex Mono", monospace;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0A0A0A;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Safe area utilities */
.pb-safe {
  padding-bottom: env(safe-area-inset-bottom);
}

@supports(padding: max(0px)) {
  .pb-safe {
    padding-bottom: max(0.5rem, env(safe-area-inset-bottom));
  }
}

/* Existing media queries */
@media (max-width: 640px) {
  .max-w-sm {
    max-width: 90%;
  }
}

@media (min-width: 641px) and (max-width: 1024px) {
  .max-w-sm {
    max-width: 20rem;
  }
}

@media (min-width: 1025px) {
  .max-w-sm {
    max-width: 24rem;
  }
}
